import { Component, OnInit } from '@angular/core';
import { UserDTO } from '../../dto/user-dto';
import { AdminService } from '../../services/admin.service';
import { Router } from '@angular/router';
import { LoginService } from '../../services/login.service';
import {Location} from "@angular/common";


@Component({
  selector: 'app-approval',
  templateUrl: './approval.component.html',
  styleUrls: ['./approval.component.css']
})
export class ApprovalComponent implements OnInit {
	loggedInSupplierId: number = 0;
  isLoggedIn:boolean =false ;

  constructor(public adminService:AdminService, private router: Router, private data: LoginService,private location:Location ) { 
    this.data.loggedInStatus.subscribe(isLoggedIn => this.isLoggedIn = isLoggedIn)

  }

  ngOnInit(): void {
    this.data.supplierId.subscribe(loggedInSupplierId => this.loggedInSupplierId = loggedInSupplierId);

  }

  public doSaveApproval(){
    this.adminService.saveSupplierApproveTerms(this.loggedInSupplierId).subscribe(() => {
      this.router.navigate(['dashboard']);
    });
  }

  backHome(){
    sessionStorage.setItem("wasLoggedIn", "0");
    this.isLoggedIn= false;
    this.location.replaceState('/'); // clears browser history so they can't navigate with back button
    this.router.navigate(['login']);
  
  }


}
