import { Component } from '@angular/core';

import { CommonComponent } from '../../components/common.component';
import { TranslateService } from '@ngx-translate/core';

import { AdminService } from '../../services/admin.service';
import { UserDTO } from '../../dto/user-dto';

import { Router } from '@angular/router';
import { ConfirmationDialogService } from '../../confirmation-dialog/confirmation-dialog.service';
import {Location} from "@angular/common";

@Component({
  selector: 'app-dashboard',
  templateUrl: 'forgot-password.component.html'
})
export class ForgotPasswordComponent extends CommonComponent {
  userDTO:UserDTO ;
  public constructor(public adminService:AdminService ,	public router: Router,private location:Location,
		public translateService: TranslateService,private confirmationDialogService: ConfirmationDialogService ) {
		super(translateService,router);
	}

	ngOnInit() {
		this.userDTO = new UserDTO();
	}

  public resetPassword(){

		// let result:string = this.isValidForm();

		// if(result == null) {
			this.confirmationDialogService.confirm('Reset Password', 'Are you sure you want to reset password ?  هل أنت متأكد من أنك تريد إعادة تعيين كلمة المرور؟',"Sure","Cancel", "md")
			.then((confirmed) => {
				if(confirmed){
					this.loading = true;

					this.adminService.resetPassword(this.userDTO).subscribe((isUpdated:boolean) => {

						this.loading = false;
						if(isUpdated){
							this.translateService.get('RESET_PASSWORD_SUCCESS').subscribe((transValue:string) => {
								alert(transValue);
							});
								
							this.location.replaceState('/'); // clears browser history so they can't navigate with back button

							this.router.navigate(['login']);
							
						}else{
							this.translateService.get('RESET_PASSWORD_USER_NOT_FOUND_ERROR').subscribe((transValue:string) => {
								alert(transValue);
							});
						}
					}, (error:any) => {

						this.loading = false;

						alert("CHANGE_PASSWORD_ERROR");
					});
				}
			});

			
			
			
			// )
			// .catch(() => 
			// 	console.log(''));
			// this.confirmUserAction("CHANGE_PASSWORD", "CONFIRM_CHANGE_PASSWORD_SAVE", ()=>{

			

  }

}
