import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UserDTO } from '../../dto/user-dto';
import { AdminService } from '../../services/admin.service';
import { CommonComponent } from '../../components/common.component';

import {Location} from "@angular/common";
import { NavigationStart, Router } from '@angular/router';
import { LoginService } from '../../services/login.service';
import { FadosConstants } from '../../fados.constants';
import { UrlService } from '../../services/url.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: 'login.component.html'
})
export class LoginComponent extends CommonComponent{

  userDTO:UserDTO = new UserDTO();

  isLoggedIn:boolean =false ;

  public constructor(public adminService:AdminService ,private location:Location , public router: Router , 
                  	public translateService: TranslateService ,private data: LoginService, public urlService:UrlService ) {
    super(translateService,router);

    this.data.loggedInStatus.subscribe(isLoggedIn => this.isLoggedIn = isLoggedIn)

    // https://stackoverflow.com/questions/34331478/angular-redirect-to-login-page 
    
    let wasLoggedIn = sessionStorage.getItem("wasLoggedIn");
    let roleID = sessionStorage.getItem("roleID");
    let userID = sessionStorage.getItem("userID");
    if (wasLoggedIn == "1" && roleID != null && userID != null ){
      this.data.setLoggedIn(true);
      this.isLoggedIn = true;
      this.data.setRoleID(parseInt(roleID));
      this.data.setUserID(parseInt(userID));
      this.router.navigate(['dashboard']);

    }
  }

  /**
   * 
   * Check this maybe usefaull to manage login 
   * 
   * 
   * https://jasonwatmore.com/post/2020/04/28/angular-9-user-registration-and-login-example-tutorial
   * 
   * 
   * https://ultimatecourses.com/blog/component-events-event-emitter-output-angular-2
   * 
   *  https://dzone.com/articles/understanding-output-and-eventemitter-in-angular
   * 
   */
  
  public doAdminLogin(){
    this.userDTO.email = this.userDTO.email.toLowerCase();

    this.adminService.loginAdmin(this.userDTO).subscribe((usersList:Array<UserDTO>)=> {
      let userDTO:UserDTO;  
      if(usersList!=null && usersList.length!=0){
          usersList.forEach(userDtoSearch => {
              if(userDtoSearch.active){
                userDTO = userDtoSearch;
              }
          });
          
          if(userDTO !=null && userDTO.active){

              if(userDTO.roleDTO.id == FadosConstants.SUPPLIER_ROLE_ID || userDTO.roleDTO.id == FadosConstants.SUPPLIER_EMPLOYEE_ROLE_ID ){
                if(userDTO.supplierDTO.membershipExpiryDate!=null &&  userDTO.supplierDTO.membershipExpiryDate!=''){

                  userDTO.supplierDTO.membershipExpiryDate = userDTO.supplierDTO.membershipExpiryDate.split("-").reverse().join("-")

                  const membershipExpired = function (firstDate, secondDate) {
                    if (firstDate.setHours(0, 0, 0, 0) <= secondDate.setHours(0, 0, 0, 0)) {
                      return true;
                    }
                    return false;
                  };
                  
                  const memberShipExpiryDate = new Date(userDTO.supplierDTO.membershipExpiryDate);
                  const today = new Date();
                  if(membershipExpired(memberShipExpiryDate,today)){
                    alert("Restaurant membership is Expired on " +userDTO.supplierDTO.membershipExpiryDate +". Please contact Fados Team to activate your account.");
                    return ;
                  }
                }else{
                  alert("Restaurant membership is Expired. Please contact Fados Team to activate your account.");
                  return ;

                }
              }
              // Very Important -->     https://fireship.io/lessons/sharing-data-between-angular-components-four-methods/
              this.data.setLoggedIn(true);
              this.data.setRoleID(userDTO.roleDTO.id);
              this.data.setUserID(userDTO.id);

              sessionStorage.setItem("wasLoggedIn", "1");
              sessionStorage.setItem("roleID", userDTO.roleDTO.id.toString());
              sessionStorage.setItem("userID", userDTO.id.toString());

              if(userDTO.supplierDTO.id !=null && userDTO.supplierDTO.id !=0){
                this.data.setSupplierId(userDTO.supplierDTO.id);
              }else{
                this.data.setSupplierId(null)
              }
              if(userDTO.roleDTO.id ==FadosConstants.SUPPLIER_ROLE_ID){
                  this.adminService.checkSupplierApproveTerms(userDTO.supplierDTO.id).subscribe((isApproved:Boolean)=> {
                      if(isApproved){
                        this.router.navigate(['dashboard']);
                      }else{
                        this.router.navigate(['approval']);
                      }
                  }, (error:any) => {
                    alert("Could not get Terms Approval status");
                  }); 
               
              }else{
                //Others transfer to normal dashboard
                this.router.navigate(['dashboard']);
              }
              
             
          } else {
            alert("This account has been deactivated, contact an admin for activation");
          }
        } else {
          alert("Wrong password,please try again");
        }
		}, (error:any) => {
			alert("Could not login to admin panel");
		});  }

 }
