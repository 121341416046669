import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class LoginService {

  private loggedInSource = new BehaviorSubject(false);
  loggedInStatus = this.loggedInSource.asObservable();

  private supplierIdSource = new BehaviorSubject(0);
  supplierId = this.supplierIdSource.asObservable();
 
  private roleIdSource = new BehaviorSubject(0);
  roleId = this.roleIdSource.asObservable();

  private userIdSource = new BehaviorSubject(0);
  userId = this.userIdSource.asObservable();

  constructor() { }

  setLoggedIn(isLoggedIn:boolean){
    this.loggedInSource.next(isLoggedIn);
  }

  setSupplierId(supplierId:number){
    this.supplierIdSource.next(supplierId);
  }
  
  setRoleID(loggedInRoleId:number){
    this.roleIdSource.next(loggedInRoleId);
  }

  setUserID(loggedInUserId:number){
    this.userIdSource.next(loggedInUserId);
  }
  
}